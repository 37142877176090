window.global ||= window;
import Popper from 'popper.js';
window.Popper = Popper;
import jQuery from 'jquery';
import * as bootstrap from 'bootstrap'
/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */


window.$ = jQuery;
window.jQuery = jQuery;

import BootBox from 'bootbox';
import Pusher from 'pusher-js';
import Toastr  from 'toastr';
import ChartJS  from 'chart.js';

window.BootBox = BootBox;
window.Pusher = Pusher;
window.Toastr = Toastr;
window.Chart = ChartJS;

import Echo from "laravel-echo";
import confetti from "canvas-confetti"

import "@fortawesome/fontawesome-pro/scss/fontawesome.scss";
import "@fortawesome/fontawesome-pro/scss/regular.scss";
import "@fortawesome/fontawesome-pro/scss/solid.scss";
import "@fortawesome/fontawesome-pro/scss/light.scss";
import "@fortawesome/fontawesome-pro/scss/brands.scss";
import "@fortawesome/fontawesome-pro/scss/duotone.scss";

import.meta.glob([
    '../images/**'
]);

let pusherKey = document.head.querySelector('meta[name="pusher-key"]');
if (pusherKey) {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: pusherKey.content,
        encrypted: true,
        wsHost: 'broadcast.dewi-online.nl',
        disableStats: true
    });
} else {
    console.error('Pusher token not found');
}

import DataTable from 'datatables.net-bs4';
DataTable(window, window.$);
import select2 from 'select2';
select2();
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
window.createEmbeddingContext = createEmbeddingContext;

import moment from 'moment';
window.moment = moment;

import('select2/dist/js/i18n/nl.js');

import 'bootstrap-colorpicker';

import * as fullCalendar from "fullcalendar";
import  "fullcalendar-scheduler";

window.fullCalendar =fullCalendar
import "fullcalendar/dist/locale/nl.js"
import "fullcalendar/dist/locale/en-gb.js"
import "pc-bootstrap4-datetimepicker/build/js/bootstrap-datetimepicker.min.js";
import 'better-dom/dist/better-dom.js'
import 'better-dateinput-polyfill/dist/better-dateinput-polyfill.js'


import 'jquery-contextmenu'
import enableRightClick from './fullcalendar-rightclick';
import './chart-gauge'
import 'iframe-resizer'
import('./bootstrap-editable4.min.js');

import  "jquery-appear-original";
import GridStack from 'gridstack/dist/gridstack-h5.js';
window.GridStack = GridStack
import NoMirrorWebcam from './mirror-webcam.js';
window.NoMirrorWebcam = NoMirrorWebcam;
import loadImage from 'blueimp-load-image';
window.loadImage = loadImage
window.confetti = confetti;

const fireConfetti = () => {
    confetti({
        particleCount: 300,
        startVelocity: 80,
        angle: 60,
        spread: 55,
        origin: { x: 0, y: 1 },
    });
    confetti({
        particleCount: 300,
        startVelocity: 80,
        angle: 120,
        spread: 55,
        origin: { x: 1, y: 1 },
    });

}
window.fireConfetti = fireConfetti;
// Load local modules'
import adminModalInstance, {AdminModal} from './modal.js';

window.AdminModal = adminModalInstance();
window.AdminModalInstance = AdminModal;

import collectionInstance from './collection';
window.jsCollection = collectionInstance;

import jsIconChoices from './icon-choices';
window.jsIconChoices = jsIconChoices
import jsProgramSection from './program-section';
window.jsProgramSection = jsProgramSection


window.getTranslation = function (key) {

    if (window.translations[key]) {
        return window.translations[key];
    }

    return key;
}

function init() {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    var rxhtmlTag = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^\/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi;
    jQuery.htmlPrefilter = function( html ) {
        return html.replace( rxhtmlTag, "<$1></$2>" );
    };

    jsCollection();

    let body = $('body');

    window.Toastr.options.closeButton = true;
    window.Toastr.options.progressBar = true;
    window.Toastr.options.positionClass = "toast-bottom-left";

    body.on('click', '[data-method]', function (ev) {
        ev.preventDefault();
        ev.stopPropagation();

        let method = $(this).data('method');
        let href = $(this).attr('href');

        let confirmText = $(this).data('confirm');
        let trans = window.confirm_translations || {};
        let deleteText = $(this).data('btn-text') ? $(this).data('btn-text') : ('<i class="fa fa-trash"></i> ' + (trans.delete || 'Delete'));
        let remoteSubmit = $(this).data('remote');
        let callback =  $(this).data('callback');

        if (!confirmText) {
            redirect();
            return;
        }


        let buttons;

        if (method == 'DELETE') {
            buttons = {
                cancel: {
                    label: '<i class="fa fa-times"></i> ' + (trans.cancel || 'Cancel')
                },
                confirm: {
                    label: deleteText,
                    className: 'btn-danger'
                }
            };
        } else {
            buttons = {
                cancel: {
                    label: '<i class="fa fa-times"></i> ' + (trans.cancel || 'Cancel')
                },
                confirm: {
                    label: '<i class="fa fa-check"></i> ' + (trans.confirm || 'Confirm')
                }
            };
        }

        BootBox.confirm({
            title: $(this).data('title'),
            message: confirmText,
            buttons: buttons,
            callback: function (result) {
                if (!result) {
                    return;
                }
                if (remoteSubmit) {
                    remote(callback);
                } else {
                    redirect();
                }

            }
        });

        function remote(callback) {
            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: href,
                method: method,
                success: function (response) {
                    if (response.status) {
                        $('.js-alert-content').html(response.message)
                        $('.js-alert-type').removeClass(function (index, css) {
                            return (css.match(/\balert-\S+/g) || []).join(' '); // removes anything that starts with "alert-"
                        }).addClass('alert-' + response.status).show();
                    }
                    if (callback) {
                        window[callback](); // Execute the function.
                    }
                }
            });
        }

        function redirect() {
            $(`<form action="` + href + `" method="post">
                       <input type="hidden" name="_token" value="` + $('meta[name="csrf-token"]').attr('content') + `">
                       <input type="hidden" name="_method" value="` + method + `">
                   </form>`)
            .appendTo($('body'))
            .submit();
        }
    });

    // Disable all buttons when submitting a form
    body.on('submit', 'form:not(.modal-form,.js-datatable-filter-form)', function () {
        body.find('input[type="submit"], button, a').addClass('disabled-for-submit disabled');
    });

    $.fn.select2.defaults.set("theme", "bootstrap");
    $.fn.select2.defaults.set("width", '100%');
    $.fn.select2.defaults.set("language", 'nl');


    var fc = $.fullCalendar;
    if(fc && fc.views.agenda && fc.views.basic) {
        enableRightClick(fc.views.agenda);
        enableRightClick(fc.views.basic);
    }
    if (fc.views.timeline) {
        enableRightClick(fc.views.timeline);
    }

    // Select2
    $(".select2").select2();

    $("[data-toggle='tooltip']").tooltip({boundary: 'window'});
    $('.tooltip-toggle').tooltip({boundary: 'window'});


    $('.treeview-link').click(function () {
        $(this).toggleClass('treeview-link--active');
        $(this).next().toggleClass('treeview-menu--show');
    });

    //Makes datatable rows clickable
    $(document).on("click touchstart", ".dataTable td", function (evt) {
        evt.stopPropagation();
        if ($(this).children().find('a').length === 0) {
            var url = $(this).parent().find('.js-open-url').data('url');
            if (url) {
                window.location.href = url;
            }
        }
    });

    $('.treeview-menu li.active').each(function () {
        $(this).closest('.treeview-menu').addClass('treeview-menu--show');
    });

    $('.main-header-link--sideNav').click(function () {
        $('body').toggleClass('body--sideNavActive');
    });

    $('.card-header').click(function () {
        $(this).parent().toggleClass('is-active');
    });

    $('.tab-link').click(function (evt) {
        evt.preventDefault();
        var tabName = $(this).attr('href');

        // Show only this tab
        $('.member-edit-content-body .tab-pane').hide();
        $('.member-edit-content-body').find(tabName).show();

        // Make nav item active
        $('#memberTab a').removeClass('active');
        $('#memberTab a[href="' + tabName + '"]').addClass('active');
    });

    $('#memberTab a').click(function (evt) {
        evt.preventDefault();
        var tabName = $(this).attr('href');

        // Show only this tab
        $('.member-edit-content-body .tab-pane').hide();
        $('.member-edit-content-body').find(tabName).show();

        // Make nav item active
        $('#memberTab a').removeClass('active');
        $(this).addClass('active');
    });

    $('.editGrid-sidebar-nav a').click(function (evt) {
        evt.preventDefault();
        var tabName = $(this).attr('href');

        // Show only this tab
        $('.editGrid-content-body .tab-pane').hide();
        $('.editGrid-content-body').find(tabName).show();

        // Make nav item active
        $('.editGrid-sidebar-nav a').removeClass('active');
        $(this).addClass('active');
    });

    $('.alert-dismissible').delay(10000).queue(function () {
        $(this).addClass("alert--hide");
    });

    $('.js-toggleSideMenu').click(function (evt) {
        evt.preventDefault();
        $('.app-wrapper').toggleClass('app-wrapper--sideNavActive');
    });

    $('.show-password').on('click', function () {
        var password = $('#password');
        if (password.prop('type') === 'password') {
            password.prop('type', 'text')
        } else {
            password.prop('type', 'password')
        }

    })


        if (document.querySelector('.category li.active')) {
            document.querySelector('.category li.active').closest('.category-items').classList.add('show');
        }

        document.querySelectorAll('.category').forEach(title => {
            const categoryItems = title.querySelector('.category-items');

            title.addEventListener('click', () => {
                if (categoryItems.classList.contains('show')) {
                    categoryItems.classList.remove('show');
                    title.querySelector('.fa').classList.remove('fa-arrow-up')
                    title.querySelector('.fa').classList.add('fa-arrow-down')
                } else {
                    categoryItems.classList.add('show');
                    title.querySelector('.fa').classList.add('fa-arrow-up')
                    title.querySelector('.fa').classList.remove('fa-arrow-down')
                }

            });
        });

}

$(document).ready(init);
